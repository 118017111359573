@media print {
  body * {
    visibility: hidden;
    margin-top: -10px;
  }
  #singleinvoice * {
    visibility: visible;
    top: -20px;
    margin-top: 0px;
    border: 0px solid transparent;
  }
  #singleinvoice {
    left: 0;
  }
  #printbtn * {
    visibility: hidden;
  }
  #markpaidbtn {
    visibility: hidden;
  }
  #markrevertbtn {
    visibility: hidden;
  }
  #producttab {
    margin-top: 40px;
  }
}

.inputNew2{
  width: 100%;
  padding: 5px;
  margin: 10px 20px !important;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: black !important;
}
.buttonOfReport {
  padding: 10px 20px;
  background-color: #ffa733; /* Orange color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  letter-spacing: 1px;
  transition: background-color 0.3s, transform 0.2s; /* Add a smooth transition */
}

.buttonOfReport:hover,
.buttonOfReport:active,
.buttonOfReport:focus {
  background-color: #ff9100; /* Slightly darker orange for hover */
  color: white;
  transform: scale(1.05); /* Slight scaling effect */
}

.buttonOfReport:disabled {
  background-color: #d3d3d3; /* Gray for disabled state */
  color: #9e9e9e;
  cursor: not-allowed;
}


.labelOfReport{
  color: white;
  margin: 0px 15px !important;
}
.headingOfReport{
  color: white;
  margin-left: 15px  !important;
  margin-top: 10px !important;
}