.main-form {
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: papayawhip;
    margin: 0 auto;
  }
  
  .submit {
    background-color: #d8bb14 !important;
    border: 1px solid #dcbb68 !important;
    color: white;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    transition: color .15s ease-in-out,
      background-color .15s ease-in-out,
      border-color .15s ease-in-out,
      box-shadow .15s ease-in-out
  }
  
  .heading-form {
    font-size: 15px;
    font-weight: 700;
    margin: 10px;
  }
  
  .submit:hover,
  .submit:focus {
    background-color: #d8bb14 !important;
    border: 1px solid #dcbb68 !important;
    color: white;
  }
  
  .address-field,
  .address-field:focus {
    border: 0px solid !important;
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  .ant-form-item-label>label {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }
  
  .paymentBtn {
    background-color: #d8bb14 !important;
    border: 1px solid #dcbb68 !important;
    color: white;
    display: inline-block;
    font-size: 13px !important;
    text-align: center;
    vertical-align: middle;
    transition: color .15s ease-in-out,
      background-color .15s ease-in-out,
      border-color .15s ease-in-out,
      box-shadow .15s ease-in-out
  }
  
  .paymentBtn:hover,
  .paymentBtn:focus {
    background-color: #d8bb14 !important;
    border: 1px solid #dcbb68 !important;
    color: white;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    transition: color .15s ease-in-out,
      background-color .15s ease-in-out,
      border-color .15s ease-in-out,
      box-shadow .15s ease-in-out
  }
  
  .paymentBtn:disabled {
    background-color: #eedc74 !important;
    border: 1px solid #eedc74 !important;
    color: white;
  
  }
  
  .paragraph p {
    padding-left: 10px;
    padding-right: 10px;
  
  }
  .ant-form input,.ant-form .ant-select-selection{
    border: 1px solid #d9d9d9;;
  }
  
  .productRow .product-bg{
    background-color: #d9d9d9;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  .totalDisplay{
    background-color: #9D2904;
    border-radius: 5px;
    color: #fff;
  }
  
  .itemName,
  .itemQuantity,
  .itemPrice {
    font-weight: 600;
    text-transform: capitalize;
    font-size: 160px;
  }
  
  .dropSelect{
    animation: glowingBtn 1.3s infinite;
    background-color: #9D2904;
    border: none;
    border-radius: 50px;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    padding: 6px 10px 6px 15px;
  }
  .payBtn:disabled{
    opacity: .5;
  }
  .payBtn:disabled span{
    color: rgb(83, 82, 82);
  }
  .datePicker{
    font-size: 14px;
    font-weight: 600;
    color: black;
  }
  .date-select input{
    padding-left: 50px;
    margin-left: 40px;
  }