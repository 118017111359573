.landing-page-wrapper {
  font-family: Verdana, Geneva, sans-serif;
}

.link {
  text-decoration: none;
  color: inherit;
}

.link:hover {
  color: inherit;
}

.link:visited {
  color: inherit;
}

.landing-header {
  z-index: 5;
  color: white;
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.7);
  align-items: center;
  padding-left: 120px;
  padding-right: 120px;
}

.display-login {
  display: none;
}

@media (max-width: 1200px) {
  .landing-header,
  .hero {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (max-width: 950px) {
  .login-page-hide {
    display: none;
  }

  .landing-header,
  .hero {
    padding-left: 20px;
    padding-right: 20px;
  }

  .display-login {
    display: block;
  }

  .hide-small {
    display: none;
  }
}

@media (max-width: 600px) {
  .hide-when-phone {
    display: none;
  }
}

@media (min-width: 1025px) {
  .show-when-small {
    display: none;
  }
}

.hero {
  padding-left: 120px;
  padding-right: 120px;
}

.hero-inner-flex {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.hero-inner-flex-signup {
  height: 400px;
  min-width: 300px;
  border-radius: 4px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 8px 6px -6px black;
  padding: 20px;
}

.hero-flex {
  min-height: 50vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
}

.hero-item {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 50%;
  min-height: 70vh;
}

.landing-nav li {
  padding: 0 15px;
}

li {
  list-style: none;
}

#animation-landing {
  color: white;
  text-shadow: 2px 2px 0px black;
  text-transform: uppercase;
  font-size: 40px;
}

#lorem {
  text-shadow: 1px 1px 1px black;
  color: white;
}

.form-signup-main {
  height: 200px;
}

.top-menu {
  height: 40px;
  border-bottom: 1px solid #d9d9d9;
}

.main-menu {
  height: 90px;
}

.landing-nav,
.landing-form {
  display: flex;
  flex-wrap: none;
  justify-content: space-between;
  align-items: center;
}

.landing-header button {
  border: none;
  background-color: inherit;
  outline: none;
}

.landing-header h2 {
  color: white;
}

.company-landing {
  height: 70px;
  filter: brightness(0) invert(1);
}

.company-landing-small {
  display: none;
}

.company-landing-main {
  height: 150px;
}

@media (max-width: 600px) {
  .company-landing-small {
    display: block;
    height: 70px;
    filter: brightness(0) invert(1);
  }

  .company-landing {
    display: none;
  }
}

@media (min-width: 1367px) {
  .show-when-small {
    display: none;
  }
}

#login-submit-landing {
  text-transform: uppercase;
  padding: 5px 15px 5px 15px;
  border-radius: 4px;
  border: 1px solid white;
}

#login-submit-landing-alternate {
  text-transform: uppercase;
  padding: 5px 15px 5px 15px;
  border-radius: 4px;
  border: 1px solid white;
}

#signup-submit-landing {
  background-color: rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
  padding: 5px 15px 5px 15px;
  border-radius: 4px;
  border: 1px solid white;
  min-width: 200px;
  color: white;
}

.landing-form li {
  margin: 0 5px 0 5px;
}

.landing-input {
  padding-left: 10px;
  min-width: 200px;
  height: 30px;
  border: none;
  font-size: 14px;
  outline: none;
  color: black;
  border-radius: 4px;
  background-color: white;
}

.landing-input:focus {
  border-bottom: 2px solid black;
}

.background-image {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-size: cover;
  background-color: #a76e31;
}

.description-text {
  font-size: 10px;
  margin-bottom: 0;
  color: #aeaba8;
}


