.img-box {
  height: 10rem;
  overflow: hidden;
}
.dashboard-flex,#groupReport-card {
  display: flex;
  /* height:76vh; */
  /* overflow-y: scroll; */
  /* align-items: start; */
}
.items {
  margin: auto 0.75em;
}
.button-style {
  position: relative;
}
.button-size-icon {
  width: 120px;
  height: 120px;
  margin: 10px 0;
  line-height: 1.125;
  border: none;
  padding: 0;
  box-shadow: none;
}

.white {
  color: #fff;
}
.menu-options {
  font-size: 14px;
  color:#1b3045;
  font-weight: 600;
}
.product-image {
  max-height: 5em;
  max-width: 5em;
  height: auto;
  margin: 0 auto;
}
.dashboard-flex .ant-col {
  margin: 1.5rem;
  /* width:13%; */
  padding:3px;
  text-align:center;
  border-radius:10px;
  cursor: pointer;
  background-color: white;
  animation:scaledown .4s linear;
  animation-fill-mode: forwards;
  box-shadow:  5px 5px 20px #bababa, 0px 0px 6px #ffffff;
}
.button-style-card{
  box-shadow:  5px 5px 20px #bababa, 0px 0px 6px #ffffff;
}
.billing .dashboard-flex .ant-col {
  /* margin: 0 !important; */
  margin: 0 10px 20px 10px !important;
  padding-bottom: 10px;
  box-shadow:  5px 5px 20px #bababa, 0px 0px 6px #ffffff !important;
}
.billing .dashboard-flex  {
  flex-wrap: wrap;
  height: 50vh;
  overflow-x: hidden;
  padding-top: 10px;
  justify-content: space-between;
}
.dashboard-flex .ant-col:hover{
  animation:scale 0.4s linear;
  animation-fill-mode: forwards;
}
.menu-options.name{
  font-family: 'Inter', sans-serif;
}

@keyframes scaledown {
  0%{
      transform: scale(1.1);
  }
  100%{
      transform: scale(1.0);
  }
}
@keyframes scale {
  0%{
      transform: scale(1);
  }
  100%{
      transform: scale(1.1);
  }
}
@media screen and (max-width:1024px){
  /* .dashboard-flex{
    display:block;
  } */
  .dashboard-flex .ant-col{
    width:20%;
    margin:auto;
    margin-top:20px;
  }
}
@media screen and (max-width: 1366px){ 
  .imgcontainer {
    padding: 2rem 0 0 !important;
}
.login-main form {
  padding: 1rem 4rem 2rem !important;
}
  .reserve-icon{
    width:25px !important;
    padding-top: 8px;
  }
  .homeLayout .bg-light {
    height: 70px;
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  padding: 5px;
  font-size: 10px;
}
.ant-btn{
  height: 25px;
  padding:0 10px;
}
.ant-table-pagination.ant-pagination {
  margin: 5px 0;
}
.inner-main .m-t-1r {
    margin-top: 4rem;
}
.bell-img {
  width: 20px;
  height: 20px;
  margin: 0 1rem;
}
  .ant-btn:hover, .ant-btn:focus{
    background:none !important;
  }
  .inner-main .container{
    width: 70vw;
  }
  /* .main-menu {
    height: 70px;
} */
  .header-info-wrapper {
    height: 30px;
    /* width: 190px; */
}
  .profile-img {
    height: 45px;
    width: 45px;
}
.order-type{
  margin:0 !important;
}
.homeLayout .collapse .nav-link {
  padding: 0 10px;
}
.badge-folder-cus {
  right: 12px;
  padding: 4px 8px;
}
  .button-size-icon {
    height: 90px;
    width: 100px;
  }
  .report-container{
    padding:0;
    display: flex;
    align-items: center;
  }
  /* .report-container .card{
    height: 60vh;
    overflow-y: scroll !important;
  } */
  #groupReport-card {
    height: auto;
}
  .dashboard-flex .ant-col{
    margin: 1rem 0.5rem;
    width: auto;
  }
  #navbarNavDropdown svg {
    width: 15px;
}
  .username, .header-info-wrapper,  .menu-options, .btn-link, .btn-link-ok,p,b,a,td, .ant-btn-link, button, .ant-input, .ant-tag  {
    font-weight: 400 !important;
    font-size: 10px !important;
  }
  .main-menu .para{
    font-size: 8px !important;
  }
  .main-menu .reserveBtn{
    padding-top: 6px !important;
  }
  .main-menu .reserveBtn img{
    height: 25px;
    width: 25px !important;
  }
  .report-container .item, h4, h5,.table2 td.sub-total, .leftButton.shadow.send{
    font-size: 12px !important;
}
 .left-menu svg{
  width: 20px;
  height: 20px;
}
.right-boxs ul li a img, .right-boxs svg{
  width: 25px;
  height: 25px;
}
.right-boxs ul li {
  margin: 8px 0 !important;
  height: 4.3rem;
  width: 5.5rem;
}
.list-group-item, .ant-statistic-title{
  font-size: 12px;
}
.company-logo-header {
  max-height: 40px;
}
.btn.btn-link{
  padding: 10px 0 !important;
}
.navbar-brand h5{
  padding: 0 !important;
}
.btn-link-ok {
    height: 50px;
}
.billing {
  padding-top: 60px;
}
.categoryListTable img {
  width: 30px;
}
.billing .dashboard-flex .ant-col{
    margin: 0 5px 20px 5px !important;
}
.billing .dashboard-flex {
  overflow-y: scroll;
}
.bill-container, .bill-container .form-container{
  margin: 0 !important;
  padding: 0 !important;
}
.table-number {
  padding: 10px 5px;
  font-size: 12px;
}
.leftButton, .rightButton {
  height: 30px !important;
  margin: 5px !important;
  padding: 0 !important;
}
.product-image {
  max-height: 3.5em;
  max-width: 3.5em;
}
/* .left-menu .list-group .list-group-item a {
  flex-direction: row;
  align-items: center;
} */
.left-menu .list-group-item, .left-menu .list-group .list-group-item a i, .product-table td {
  padding: 4.5px !important;
}
}


.gp-box-total .ant-statistic-content{
  padding: 20px 0;
}

.gp-box-shadow svg, .ant-statistic-content-value-int{
  height:20px;
}
.gp-box-total .ant-statistic-content-prefix div, .gp-box-total .ant-statistic-content-prefix div i,  .gp-box-total .ant-statistic-content-value{
  color: #fff ;
  font-size: 25px !important;
}
.gp-box-total svg{
  height: 30px !important;
}
/* new class */

.offerIcon{
  position: absolute;
  left: 0;
  top: 0;
  margin-right: 100px;
}