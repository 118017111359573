.cancelReasons .col {
    border: 1px solid #6c757d;
    margin: 8px 30px;
    width: 80%;
    justify-content: space-around;

}

.ant-notification {
    top: 22% !important
}

.orderType{
    color: red !important;
    font-size: 14px !important;
    font-weight: bold !important;
}

@media print {
    #tablePrint * {
        /* visibility: visible;
        top: -20px;
        margin-top: 0px;
        border: 0px solid transparent; */
        visibility: visible;
      border: 0px solid transparent;
      width: 100px;
      margin-top: 10px;
    }

    .TableSection{
        height: max-content;
        width:max-content;
        margin-left: 40px;
      }
    .btn-ab , .view-title{
        display: none;
    }
    .mycontent-right{
        width: 900px;
    }
}
.span-field {
    background-color: rgba(128, 128, 128, 0.08);
    padding: 5px 20px;
    cursor: pointer
}
.view-btn,.view-btn:hover{
    border: 1px solid #dcbb68 !important;
    color: #fff !important;
}